import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CustomerInterface, CustomerPaginatedInterface, CustomerPaymentResponseInterface, CustomerResponseInterface, DataTransUnionAndRues } from '../interfaces/customer-interface';
import { Observable, tap } from 'rxjs';
import { CustomersComunicateComponentsService } from './customers-comunicate-components.service';
import { CustomerPaymentStoreResponseInterface, CustomerShowHistorialResponseInterface } from '../interfaces/customer-information.interface';

@Injectable({
      providedIn: 'root'
})
export class CustomersService {
      private http = inject(HttpClient);
      private apiUrl = environment.apiUrl + '';
      private baseUrl = environment.apiUrl;
      private baseUrlCustomer = environment.apiUrlCustomer;


      constructor(
            private _customersComunicateService: CustomersComunicateComponentsService
      ) { }


      /**
       * Envía un enlace de WhatsApp para Deceval a un cliente específico.
       * @param customer_id - ID del cliente al que se enviará el enlace.
       * @returns Observable de la respuesta del servidor.
       */
      sendWhatsappLinkDeceval(customer_id: number) {
            return this.http.get(`${environment.apiUrl}/send-whatsapp-link-deceval/${customer_id}`);
      }

      /**
       * Actualiza los datos de un cliente.
       * @param customer - Objeto con los datos del cliente a actualizar.
       * @param customerId - ID del cliente a actualizar.
       * @returns Observable con la respuesta de la actualización.
       */
      updateCustomer(customer: CustomerInterface, customerId: number): Observable<any> {
            return this.http.put<CustomerResponseInterface>(this.apiUrl + '/actualizar/' + customerId, customer).pipe(tap(() => {
                  this._customersComunicateService.refreshCustomersList();
            }));
      }


      /**
       * Genera un nuevo enlace de Pagaré para el cliente.
       * @param identification - Identificación del cliente para regenerar el token.
       * @returns Observable con la respuesta de la operación.
       */
      generateNewLinkPagare(identification: string) {
            return this.http.post(this.baseUrl + '/regenerate-token/', { identification });
      }

      /**
       * Descarga el contrato en formato PDF para un cliente.
       * @param customerId - ID del cliente.
       * @returns Observable con el archivo en formato Blob.
       */

      /**
       * Obtiene los créditos pendientes de un cliente.
       * @param id - ID del cliente.
       * @returns Observable con los créditos pendientes.
       */
      getCreditsPendintsCustomer(id: number) {
            return this.http.get<CustomerPaymentResponseInterface>(this.baseUrl + '/clientes-pagos/' + id);
      }

      /**
       * Almacena los créditos pendientes de un cliente.
       * @param data - Datos de los créditos a almacenar.
       * @returns Observable con la respuesta de la operación.
       */
      storeCreditsPendintsCustomer(data: any) {
            return this.http.post<CustomerPaymentStoreResponseInterface>(this.baseUrl + '/clientes-pagos', data);
      }

      getCustomerInfo(id: number): Observable<CustomerResponseInterface> {
            return this.http.get<CustomerResponseInterface>(
                  `${this.apiUrl}/${id}`
            );
      }



      /**
       * Obtiene una lista de clientes filtrados.
       * @param formData - Datos del formulario utilizados para filtrar clientes.
       * @returns Observable con la lista paginada de clientes filtrados.
       */
      getCustomersFilter(formData: any) {
            const params = new HttpParams({ fromObject: formData });
            return this.http.get<CustomerPaginatedInterface>(this.baseUrlCustomer + '/customers/all', { params });
      }

      /**
       * Obtiene información financiera de Transunion y RUES para un cliente.
       * @param id - ID del cliente.
       * @returns Observable con la información financiera.
       */
      getInfoTransunionAndRues(id: number): Observable<DataTransUnionAndRues> {
            return this.http.get<DataTransUnionAndRues>(this.baseUrlCustomer + '/customers/finantial-info/' + id);
      }

      /**
       * Rechaza a un cliente específico.
       * @param customerId - ID del cliente a rechazar.
       * @returns Observable con la respuesta del rechazo.
       */
      rejectCustomer(customerId: number): Observable<any> {
            return this.http.put<CustomerResponseInterface>(`${this.baseUrlCustomer}/customers/reject-customer/${customerId}`, {}).pipe(tap(() => {
                  this._customersComunicateService.refreshCustomersList();
            }));
      }


      /**
       * Obtiene los datos de un cliente por su ID.
       * @param customerId - ID del cliente.
       * @returns Observable con los datos del cliente.
       */
      getCustomerById(customerId: number) {
            return this.http.get<any>(`${this.baseUrlCustomer}/customers/${customerId}`);
      }

      /**
       * Genera y descarga un archivo de paz y salvo para un cliente.
       * @param customerId - ID del cliente.
       * @returns Observable con el archivo en formato Blob.
       */
      peaceSafeCustomer(customerId: number): Observable<any> {
            const options = {
                  headers: new HttpHeaders({
                        "Content-Type": "application/json",
                  }),
                  responseType: "blob" as "json",
            };

            return this.http.get<Blob>(this.baseUrlCustomer + "/customers" + "/paz-salvo/" + customerId, options);
      }

      /**
       * Descarga el contrato en formato PDF para un cliente.
       * @param customerId - ID del cliente.
       * @returns Observable con el archivo en formato Blob.
       */
      downloadPDFContract(customerId: any): Observable<HttpResponse<Blob>> {
            return this.http.get(this.baseUrlCustomer + "/customers" + "/contract/" + customerId, { responseType: 'blob', observe: 'response' });
      }

      /**
       * Obtiene el estado de cuenta de un cliente.
       * @param customerId - ID del cliente.
       * @returns Observable con el estado de cuenta.
       */
      getAccountStatement(customerId: number) {
            return this.http.get(this.baseUrlCustomer + "/customers" + "/account-statement/" + customerId);
      }

      /**
       * Descarga un archivo de Deceval para un cliente.
       * @param customer - Objeto con los datos del cliente.
       * @returns Observable con la respuesta de la operación.
       */
      downloadFileDeceval(customerId: number) {
            return this.http.get(this.baseUrlCustomer + "/customers" + "/search-file-deceval/" + customerId);
      }

            /**
       * Obtiene el historial de un cliente.
       * @param id - ID del cliente.
       * @param credits - Booleano para obtener la consulta con los detalles de los créditos.
       * @returns Observable con el historial del cliente.
       */
      getCustomerHistorial(id: number): Observable<CustomerShowHistorialResponseInterface> {

            return this.http.get<CustomerShowHistorialResponseInterface>
            (`${this.baseUrlCustomer}/history/${id}?credits=true`);
      }



}
