import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseBusinessType } from '../../auth/register/interfaces/register-service-response.interface';
import { generalParameterResponseInterface } from '../interfaces/general-parameter';
import { RoleResponseInterface } from '../interfaces/roles.interface';

@Injectable({
      providedIn: 'root',
})
export class ParametersService {
      /**
       * Servicio para manejar parámetros generales de la aplicación.
       * @class ParametersService
       */
      private http = inject(HttpClient);
      private apiUrl = environment.apiUrlIAm;

      /**
       * Obtiene las ciudades por el ID del departamento.
       * @param {number} departmentId - El ID del departamento.
       * @returns {Observable<any>} Observable con la lista de ciudades.
       */
      getCitiesByDepartments(departmentId: number) {
            return this.http.get(
                  this.apiUrl + '/ciudades/departamento/' + departmentId
            );
      }

      /**
       * Obtiene todas las ciudades.
       * @returns {Observable<generalParameterResponseInterface>} Observable con la lista de todas las ciudades.
       */
      getCities() {
            return this.http.get<generalParameterResponseInterface>(
                  this.apiUrl + '/ciudades/all-cities'
            );
      }

      /**
       * Obtiene todos los departamentos.
       * @returns {Observable<any>} Observable con la lista de departamentos.
       */
      getDepartments() {
            return this.http.get(this.apiUrl + '/departamentos');
      }

      /**
       * Obtiene la lista de bancos.
       * @returns {Observable<any>} Observable con la lista de bancos.
       */
      getBanks() {
            return this.http.get(this.apiUrl + '/bancos');
      }

      /**
       * Obtiene todos los roles disponibles.
       * @returns {Observable<RoleResponseInterface>} Observable con la lista de roles.
       */
      getAllRoles() {
            return this.http.get<RoleResponseInterface>(
                  this.apiUrl + '/roles/all'
            );
      }

      /**
       * Obtiene una lista de tipos de negocios desde el API.
       * @returns {Observable<ResponseBusinessType[]>} Observable con la lista de tipos de negocios.
       */
      getBusinessTypes() {
            return this.http.get<ResponseBusinessType[]>(
                  `${environment.apiUrlCustomer}/customer-business-types/all`
            );
      }
}
