import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustmersCodeOptService } from '../../services/customer-code-opt.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { toggleLoader } from '../../../../shared/helpers/functions-helper.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BreadcrumbComponent } from '../../../../shared/components/breadcrumb/breadcrumb.component';
import { TableComponent } from '../../../../shared/components/table/table.component';
import { TableService } from '../../../../shared/services/table.service';
import { Breadcrumb } from '../../../../shared/interfaces/breadcrumb.interface';
import { CodeOtpQueryParams } from '../../interfaces/code-otp-query-params.interface';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { Subscription } from 'rxjs';

@Component({
        selector: 'app-customers-code-otp-list',
        templateUrl: './customers-code-otp-list.component.html',
        standalone: true,
        imports: [
                CommonModule,
                FormsModule,
                BreadcrumbComponent,
                TableComponent
        ]
})
export class CustomersCodeOtpListComponent implements OnInit, OnDestroy {

        private subscriptions: Subscription[] = [];
        currentPage: number = 1;
        queryParams: CodeOtpQueryParams = { codeOrIdentification: '' };
        breadcrumbItems: Array<Breadcrumb> = [
                {
                        label: "Códigos OTP",
                        url: "/codigos-otp/listado/1",
                }
        ];
        dataTable = {
                table: {
                        header: [
                                { name: 'Código', code: 'code' },
                                { name: 'Cliente', code: 'customer.name', pipe: 'title' },
                                { name: 'Identificación', code: 'customer.identification' },
                                { name: 'Vencimiento', code: 'deadline' },
                                { name: 'Estado', code: 'state', badge: 'color_badge' },
                        ],
                        body: [],
                        paginator: {}
                },
                search: {
                        dataForm: [
                                { label: "Código o Identificación", control: "codeOrIdentification", type: "text" },
                        ],
                }
        }

        constructor(
                private _tableService: TableService,
                private custmersCodeOptService: CustmersCodeOptService,
                private loaderService: LoaderService,
                private modalService: ModalServiceAlert,
        ) { }

        /**
         * Inicializa el componente.
         * Refresca los parámetros, busca los códigos OTP y configura los suscriptores.
         */
        ngOnInit(): void {
                this.refreshParams();
                this.searchCodesOpt();
                this.callSubscribers();
        }

        ngOnDestroy(): void {
                this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }

        /**
         * Configura los suscriptores para las acciones de la tabla.
         * Maneja las acciones de paginación, actualización y búsqueda.
         */
        callSubscribers(): void {
                const tableSubscription = this._tableService.actionsInTable$.subscribe((data: any) => {
                        console.log('data', data);
                        if (data) {
                                switch (data.action) {
                                        case 'page':
                                                this.currentPage = data.data.page;
                                                this.searchCodesOpt();
                                                break;
                                        case 'refresh':
                                                this.refreshParams();
                                                this.currentPage = 1;
                                                this.searchCodesOpt();
                                                break;
                                        case 'search':
                                                this.currentPage = 1;
                                                data.data.form.action = 'buscar';
                                                this.queryParams = data.data.form;
                                                this.searchCodesOpt();
                                                break;
                                }
                        }
                });
                this.subscriptions.push(tableSubscription);
        }

        /**
         * Busca los códigos OTP y actualiza la tabla con los resultados.
         * Maneja los errores mostrando un modal de alerta.
         */
        searchCodesOpt() {
                toggleLoader(this.loaderService, true);
                const searchSubscription = this.custmersCodeOptService.getCodesOpt(this.currentPage, this.queryParams.codeOrIdentification).subscribe(
                        response => {
                                console.log('response', response);
                                toggleLoader(this.loaderService, false);
                                this.dataTable.table.body = response.data.data.map((item: any) => {
                                        return {
                                                ...item,
                                                customer: {
                                                        ...item.customer,
                                                        name: item.customer.name + ' ' + item.customer.last_name
                                                },
                                                color_badge: this.getStateCodeColorCustomer(item.state)
                                        }
                                });
                                this.dataTable.table.paginator = response.data;
                                this._tableService.infoTable(this.dataTable);
                        },
                        error => {
                                toggleLoader(this.loaderService, false);
                                this.modalService.openModal('Error', 'Ha ocurrido un error al cargar los datos', 'error');
                                console.error(error);
                        }
                );
                this.subscriptions.push(searchSubscription);
        }

        /**
         * Reinicia los parámetros de búsqueda a sus valores por defecto.
         */
        refreshParams(): void {
                this.queryParams = {
                        codeOrIdentification: '',
                };
        }

        /**
         * Determina el color del badge basado en el estado del código OTP.
         * @param {string} state - El estado del código OTP.
         * @returns {string} El color correspondiente al estado.
         */
        getStateCodeColorCustomer(state: string) {
                switch (state) {
                        case 'Confirmado':
                                return 'success';
                        case 'Pendiente':
                                return 'warning';
                        case 'Expirado':
                                return 'danger';
                        default:
                                return 'danger';
                }
        }
}
