<br>
<div class="d-flex justify-content-center align-items-center">
      <img width="270" height="70" src="https://ccpasto.b-cdn.net/wp-content/uploads/2022/11/logo_RUES-1.png" alt="">
</div>
<h5>Información Rues</h5>
<br>
<table class="table table-striped table-bordered">
      <thead class="">
            <tr>
                  <th>Fechas Matricula <i class="fa-solid fa-calendar-days"></i> </th>
                  <th>Estado de la matricula <i class="fa-solid fa-satellite-dish"></i> </th>
                  <th>Fecha de renovacion <i class="fa-solid fa-calendar-days"></i> </th>
                  <th>Ultimo año renovado <i class="fa-solid fa-pen"></i> </th>
            </tr>
      </thead>
      <tbody>
            @for ( info of infoRues; track $index ) {
            <tr>
                  <td>{{ info.fechaMatricula }}</td>
                  <td>{{ info.estadoMatricula }}</td>
                  <td>{{ info.fechaRenovacion }}</td>
                  <td>{{ info.ultimoAnoRenovado || 'No disponible' }}</td>
            </tr>
            }
      </tbody>
</table>
<br>
