<div class="d-flex justify-content-center align-items-center mb-3">
      <img width="180" height="60" src="https://seeklogo.com/images/T/transunion-logo-50B5A64A7C-seeklogo.com.png" alt="Logo transunion">
</div>
<h5>Información Score</h5>
<br>
<div class="content-table transunion">
      <div class="table-overflow">
            <table class="table table-striped">
                  <thead>
                        <tr>
                              <th>Score <i class="fa-solid fa-table"></i> </th>
                              <th>Grupo <i class="fa-solid fa-group-arrows-rotate"></i> </th>
                              <th>Negative Reason Code <i class="fa-solid fa-minus"></i> </th>
                              <th>Positive Reason Code <i class="fa-solid fa-plus"></i> </th>
                              <th>Subpoblación <i class="fa-solid fa-people-group"></i> </th>
                        </tr>
                  </thead>
                  <tbody>
                        @for (info of scoreTable; track $index) {
                        <tr>
                              <td>{{ info.score }}</td>
                              <td>{{ info.grupo }}</td>
                              <td>{{ info.negativeReasonCode }}</td>
                              <td>{{ info.positiveReasonCode || 'No disponible' }}</td>
                              <td>{{ info.subpoblacion || 'No disponible' }}</td>
                        </tr>
                        }
                  </tbody>
            </table>
      </div>
</div>
<br>

<h5>Resumen Endeudamiento</h5>
<br>
<table class="table table-striped">
      <thead>
            <tr>
                  <th>Obligaciones <i class="fa-solid fa-list-ul"></i> </th>
                  <th>Cantidad <i class="fa-solid fa-bars-progress"></i> </th>
                  <th>Saldo Total <i class="fa-solid fa-money-check-dollar"></i> </th>
                  <th>Cuota <i class="fa-solid fa-money-from-bracket"></i> </th>
            </tr>
      </thead>
      <tbody>
            @for ( obligacion of obligacionesT; track $index ) {
            <tr>
                  <td>{{ obligacion.tipo }}</td>
                  <td>{{ obligacion.cantidad }}</td>
                  <td>{{ obligacion.saldoTotal }}</td>
                  <td>{{ obligacion.cuota }}</td>
            </tr>
            }
      </tbody>
</table>
